<template>
  <v-card
    class="my-vcard-style"
    color="#ECEFF1"
  >
    <br>
    <h3 class="my-card-title-style">
      Graph
    </h3>
    <v-card-text
      class="text-body-1 text-center"
    >
      <p
        v-if="!collectionExist"
        class="alert-style"
      >
        No Data to Display.
      </p>
      <base-line-chart
        v-if="collectionExist"
        :chart-data="dataCollection"
        :options="options"
      />
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    name: 'ReportGraph',
    data: () => ({
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    }),
    computed: {
      graphData () {
        return this.$store.getters['reports/getGraphData'];
      },
      formData () {
        return this.$store.getters['reports/getFormData'];
      },
      dataExist () {
        return this.graphData.length > 0;
      },
      collectionExist () {
        return Object.keys(this.dataCollection).length > 0;
      },
      dataCollection () {
        if (this.dataExist === false) {
          return {};
        }
        return this.generateDataCollection();
      },
    },
    methods: {
      generateDataCollection () {
        const actionCount = [];
        const labels = [];
        for (const result of this.graphData) {
          actionCount.push(result.doc_count);
          labels.push(result.key);
        }
        return {
          labels: labels,
          datasets: [
            {
              data: actionCount,
              label: this.formData.reportType,
              borderColor: '#3e95cd',
              fill: false,
            },
          ],
        };
      },
    },
  };
</script>
<style scoped>
.my-vcard-style {
  border-radius: 15px;
  margin-right: 20px;
}
.my-card-title-style {
  color: #37474F;
  font-size: 18px;
  text-align: center;
}
.alert-style {
  color: #C62828;
  text-align: center;
}
</style>
