var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"reports","fluid":"","tag":"section"}},[_c('br'),_c('h2',{staticClass:"title-style"},[_c('v-icon',{staticClass:"icon-style"},[_vm._v(" mdi-chart-bar ")]),_vm._v(" Reports ")],1),_c('v-card',{attrs:{"shaped":"","color":"#CFD8DC"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"my-vcard-style",attrs:{"color":"#ECEFF1"}},[_c('br'),_c('h3',{staticClass:"my-card-title-style"},[_vm._v(" Generate Report ")]),_c('v-form',{model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-card-text',{staticClass:"text-body-1 text-center"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":['DigiBoard Actions', 'Banner Count'],"label":"Report Type*","rules":[(v) => !!v || 'Please select the type of report']},model:{value:(_vm.reportType),callback:function ($$v) {_vm.reportType=$$v},expression:"reportType"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.digiBoards,"item-value":"location","item-text":"location","label":"Digi-Board*","rules":[(v) => !!v || 'Please select a Digi-Board.'],"single-line":"","return-object":""},model:{value:(_vm.selectedDigiBoard),callback:function ($$v) {_vm.selectedDigiBoard=$$v},expression:"selectedDigiBoard"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"start date"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-datetime-picker',{attrs:{"label":"Dispalyed From*","date-format":"yyyy-MM-dd","time-format":"HH:mm:ss","text-field-props":{
                          suffix: _vm.userTimeZone,
                          errorMessages: errors,
                          class: 'custom-label-color',
                        },"date-picker-props":{
                          headerColor: '#37474F',
                          max: _vm.maxStartDate
                        },"time-picker-props":{
                          headerColor: '#37474F'
                        }},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',{attrs:{"color":"#37474F","large":""}},[_vm._v(" mdi-calendar ")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',{attrs:{"color":"#37474F","large":""}},[_vm._v(" mdi-alarm ")])]},proxy:true}],null,true),model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-datetime-picker',{attrs:{"label":"Displayed To","text-field-props":{
                        persistentHint: true,
                        suffix: _vm.userTimeZone,
                      },"date-picker-props":{
                        headerColor: '#37474F',
                        min: _vm.minEndDate,
                        max: _vm.maxEndDate
                      },"time-picker-props":{
                        headerColor: '#37474F'
                      }},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',{attrs:{"color":"#37474F","large":""}},[_vm._v(" mdi-calendar ")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',{attrs:{"color":"#37474F","large":""}},[_vm._v(" mdi-alarm ")])]},proxy:true}]),model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}})],1)],1)],1),_c('p',{staticClass:"text-sm-left"},[_vm._v(" *Indicates required field ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"#FF3700","disabled":!_vm.isFormComplete},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Generate ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('report-graph')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('report-summary')],1)],1),_c('centre-spinner',{attrs:{"loading":_vm.spinnerLoading}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }